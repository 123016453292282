<template>
    <!--<div class="background" ref="background">

    </div>-->
    <div class="container" :style="{background : props.color, color: props.inverted?'black':'white'}" ref="container">

        <h1 class="cinzel-font ">{{ props.message }}</h1>

    </div>
    <div class="whitebar top slideinright" ref="topbar">
            
    </div>
    <div class="whitebar bottom slideinleft" ref="bottombar">
            
    </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    message: String,
    submessage: String,
    color: String,
    ttl: Number,
    inverted: Boolean
})


</script>

<style scoped>

.container{
    text-align: center;
    position: absolute;
    width: 50vh;
    left: 50%;
    transform: translateX(-50%);
    height: 10vh;
    top: 17.5vmin;

    animation: fadein 0.7s;
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.container.out{
    animation: fadeout 0.7s;
}

.background{
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin-left: -50vw;
    margin-top: -30vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.background.out{
    animation: fadeout 0.7s;
}

.whitebar{
    position: absolute;
    width: 50vh;
    left: 50%;
    transform: translateX(-50%);
    height: 1vmin;
    background: linear-gradient(90deg, rgba(0,0,0,0), rgb(190, 86, 0, 0.7), rgba(0,0,0,0));
}

@keyframes slideinleft {
    0% {
        opacity: 0;
        transform: translateX(-150%);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%);
    }
}

@keyframes slideinright {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%);
    }
}

@keyframes slideoutleft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

@keyframes slideoutright {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.whitebar.top{
    top: calc(17.5vmin + 10vh - 1.5vmin);
    animation: slideinleft 0.5s;
}
.whitebar.top.out{
    animation: slideoutleft 0.5s;
    transform: translateX(-100%);
}

.whitebar.bottom{
    top: 18.0vmin;
    animation: slideinright 0.5s;
}
.whitebar.bottom.out{
    animation: slideoutright 0.5s;
    transform: translateX(100%);
}


h1{
    padding-top: 0vmin;
    font-size: 4vh;
}

h1, h3{
    color: rgba(39, 39, 39, 0.8);
}

</style>
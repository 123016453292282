<template>
  <div id="woodenbackground" :class="containerClasses">
      <div id="gamecontainer" :class="containerClasses">
        
        <div class="cardstaple">
          <div class="bottomcard">
            <PlayingCard :value="'OP'" :color="'none'" :isOpened="false" :shadow="true"/>
          </div>
        </div>
        <div class="cardstaple a1">
          <div class="">
            <PlayingCard :value="'OP'" :color="'none'" :isOpened="false" :shadow="true"/>
          </div>
        </div>
        <div class="cardstaple a2">
          <div class="">
            <PlayingCard :value="'OP'" :color="'none'" :isOpened="false" :shadow="true"/>
          </div>
        </div>
        <div class="cardstaple a3">
          <div class="">
            <PlayingCard :value="'OP'" :color="'none'" :isOpened="false" :shadow="true"/>
          </div>
        </div>
        <div class="cardstaple a4">
          <div class="">
            <PlayingCard :value="gamestore.trumpfCard?.value" :color="gamestore.trumpfCard?.color" :opened="gamestore.trumpfCard != undefined" ref="topcard" :shadow="true"/>
          </div>
        </div>
        <div id="trumpflabel" class="cinzel-font">
          Trumpf

        </div>

        <div id="playercontainer" class="cinzel-font">
        
          <PlayerProgress />

        </div>

        <div id="stichholder">
          <StichHolder />

        </div>
      </div>
  </div>

  <div id="bottom">
    <CardHolder />
  </div>
  <div id="topmiddle">
    <MiniMessageBand message="Dein Zug!" submessage="gamestore.stichWinnerSub" color="linear-gradient(90deg, rgba(0,0,0,0), rgba(200,200,200,0.5), rgba(0,0,0,0))" :ttl="3" :inverted="true" v-if="gamestore.selfid == gamestore.currentuserid && !gamestore.animating"/>
  </div>
  <div id="overlaycontainer">
    <StichPredictor v-if="gamestore.predicting && gamestore.currentuserid === gamestore.selfid && !gamestore.animating"/>
    <div v-for="event in gamestore.client.state.bannerEvents" :key="event[0]">
      <MessageBand :message="event[0]" :submessage="event[1]" :color="event[2]" :ttl="event[3]" @remove="removeBands()"/>
    </div>
    <LeaveWarning v-if="gamestore.leaveWarning" :alert="true"/>
  </div>
  <PointsCollapsible />
</template>

<script lang="ts" setup>
import CardHolder from './components/card/CardHolder.vue';
import { useGameStore } from '@/stores/gamestore';
import PlayingCard from './components/card/PlayingCard.vue';
import StichHolder from './components/card/StichHolder.vue';
import LeaveWarning from './components/overlays/LeaveWarning.vue';
import MessageBand from './components/overlays/MessageBand.vue';
import StichPredictor from './components/overlays/StichPredictor.vue';
import PointsCollapsible from './components/UI/PointsCollapsible.vue';

import { computed, ref, watch } from 'vue';
import PlayerProgress from './components/UI/PlayerProgress.vue';
import MiniMessageBand from './components/overlays/MiniMessageBand.vue';

const topcard = ref();

const gamestore = useGameStore();

function rotateTopCard(){
  topcard.value.rotateCard();
}

watch(() => gamestore.rotateTrumpf, () => {
  if (gamestore.rotateTrumpf){
    rotateTopCard();
    gamestore.rotateTrumpf = false;
  }
})

setInterval(() => {
  gamestore.updateScreenDetails()
}, 1000)

const containerClasses = computed(() => {
  return {
    touch: gamestore.touchDevice,
    vertical: gamestore.verticalScreen,
    over169: gamestore.over16to9,
    horizontal: !gamestore.verticalScreen,
    yellow: gamestore.trumpfCard?.color === 'yellow',
    blue: gamestore.trumpfCard?.color === 'blue',
    red: gamestore.trumpfCard?.color === 'red',
    green: gamestore.trumpfCard?.color === 'green'
  };
});

function removeBands() {
  gamestore.client.state.bannerEvents = [];
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Noto+Color+Emoji&display=swap');

#overlaycontainer {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#topmiddle {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

#bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20vmin;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  backdrop-filter: blur(5px);
}

.bottomcard{
  box-shadow: -2vmin 2vmin 6vmin rgba(0, 0, 0, 0.726) , inset -2vmin 2vmin 6vmin rgb(0, 0, 0, 0.726);
  border-radius: 10px;
}

#trumpflabel {
  text-align: center;
  position: absolute;
  top: calc(32vh + 29vmin);
  font-weight: bold;
  left: calc(20.2vw + 10vmin) ;
  transform: translateX(-50%);
  font-size: 5vmin;
  color: rgba(150, 150, 150, 0.774);
  text-shadow: 0 0 1vmin rgba(49, 30, 0, 0.781);
}

#stichholder {
  position: absolute;
  width: 20vmin;
  height: 30vmin;
  top: calc(20vh + 5vmin);
  right:20.2vw;
}

#gamecontainer.vertical #stichholder{
  top: calc(30vh + 5vmin);
}

#playercontainer {
  position: absolute;
  width: 100%;
  height: 30vmin;
  top: 10vmin;
  left: 0;
  text-align: center;
}

#gamecontainer.vertical #playercontainer{
  position: absolute;
  width: 100%;
  height: 30vmin;
  top: 8vh;
  left: 0;
  text-align: center;
}
#gamecontainer.horizontal #playercontainer{
  transform: scale(1.5);
}

#gamecontainer.horizontal > #stichholder{
  right: 50vw;
  transform: translateX(50%);
}

.cardstaple {
  position: absolute;
  width: 20vmin;
  height: 30vmin;
  top: 30vh;
  left:20.2vw;
  transform: scale(0.9);
}

.cardstaple.a1{
  top: 29.5vh;
  left: 20vw;
}

.cardstaple.a2{
  top: 29vh;
  left: 19.8vw;
}

.cardstaple.a3{
  top: 28.5vh;
  left: 19.6vw;
}

.cardstaple.a4{
  top: 28vh;
  left: 19.4vw;
}

#gamecontainer{
  transform: scale(0.7);
  width: 100%;
  height: 100%;
  position: relative;
}

#gamecontainer.vertical{
  transform: scale(1) translateY(8vmin);
}

#gamecontainer.vertical.over169{
  transform: scale(1) translateY(8vmin);
}


#woodenbackground {
  position: absolute;
  overflow: hidden;
  transform: perspective(1000px) rotateX(15deg) scale(1.10);
  width: 110%;
  left: -5%;
  height: 110%;
  bottom: 0;
  box-shadow: 0 0 20vmin rgba(0, 0, 0, 1);
}

#woodenbackground.vertical.over169 {
  bottom: 0vmin;
}

#woodenbackground::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  background: url('~@/assets/moroccan-flower-dark.webp') 0 0 repeat;
  background-size: 50vmin;
}

#woodenbackground::after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -30vmin;
  left: -50%;
  z-index: -1;
  background: radial-gradient(rgba(197, 194, 187, 0.151), rgba(197, 194, 187, 0.151) 50%, rgba(30, 30, 30, 0) 70%,rgba(30, 30, 30, 0)) 0 0 no-repeat;
  transition-duration: 2s;
  background-position: 50% 40%;
  background-size: 90vw 90vw;
}

#woodenbackground.vertical.over169::after {
  background-position: 50% 25%;
}

#woodenbackground.yellow::after {
  transition-duration: 2s;
  background-color: rgba(179, 147, 2, 0.356);
  background-blend-mode: multiply;
}

#woodenbackground.blue::after {
  transition-duration: 2s;
  background-color: rgba(0, 21, 141, 0.356);
  background-blend-mode: multiply;
}

#woodenbackground.red::after {
  transition-duration: 2s;
  background-color: rgba(179, 2, 2, 0.356);
  background-blend-mode: multiply;
}

#woodenbackground.green::after {
  transition-duration: 2s;
  background-color: rgba(6, 105, 2, 0.418);
  background-blend-mode: multiply;
}

#woodenbackground2 {
  position: absolute;
  overflow: hidden;
  transform: perspective(1000px) rotateX(-30deg) scale(1.10);
  filter: brightness(0.6);
  width: 100%;
  height: 10vmin;
  bottom: 25vmin;

}

#woodenbackground2.vertical.over169 {
  bottom: 35vmin;
}

#woodenbackground2::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  background: linear-gradient(rgba(0, 0, 0, 0.315), rgba(0, 0, 0, 0.993)), url('~@/assets/moroccan-flower-dark.webp') 0 0 repeat;
  background-size: 50vmin;
}


</style>

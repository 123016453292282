<template>
    <div class="playercontainer cinzel-font" v-for="player of gamestore.players" :key="player.id">
        <!-- <div class="arrow-right cinzel-font" v-if="player.id == gamestore.players[0].id" >
            •
        </div> -->
        <PlayerInfo :player="player" :compact="props.compact" :golden="player.id === gamestore.selfid" :disconnected="player.disconnected"/>

        <!-- <div class="arrow-right cinzel-font" v-if="player.id != gamestore.players[gamestore.players.length - 1].id" >
            →
        </div> -->
        <!-- <div class="arrow-right cinzel-font" v-else>
            •
        </div> -->

    </div>
</template>

<script setup>
import PlayerInfo from './PlayerInfo.vue';
import { useGameStore } from '@/stores/gamestore';
import { defineProps } from 'vue';

const props = defineProps({
    compact: Boolean
})

const gamestore = useGameStore();

</script>

<style scoped>

.playercontainer{
    display: inline-block;
    font-size: 2vh;
    text-align: left;
}

.arrow-right{
    color: white;
    display: inline-block;
}

</style>
<template>
    <!--<div class="background" ref="background">

    </div>-->
    <div class="container" :style="{backgroundColor : props.color}" ref="container">

        <h1 class="cinzel-font ">{{ props.message }}</h1>
        <h3 class="cinzel-font ">{{ props.submessage }}</h3>
    </div>
    <div class="whitebar top" ref="topbar">
            
    </div>
    <div class="whitebar bottom" ref="bottombar">
            
    </div>
</template>

<script setup>
import { defineProps, onBeforeUnmount, ref, defineEmits } from 'vue'

const props = defineProps({
    message: String,
    submessage: String,
    color: String,
    ttl: Number
})

const topbar = ref();
const bottombar = ref();
//const background = ref();
const container = ref();

const emit = defineEmits(['remove'])

let delay = setTimeout(() => {
    topbar.value.classList.add('out');
    bottombar.value.classList.add('out');
    container.value.classList.add('out');
    //background.value.classList.add('out');
    delay = setTimeout(() => {
        emit('remove');
    }, 500);
}, props.ttl * 1000);

onBeforeUnmount(() => {
    clearTimeout(delay)
})

</script>

<style scoped>

.container{
    text-align: center;
    position: absolute;
    width: 100vw;
    margin-left: -50vw;
    height: 30vh;
    backdrop-filter: blur(5px);

    animation: fadein 0.7s;
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.container.out{
    animation: fadeout 0.7s;
}

.background{
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin-left: -50vw;
    margin-top: -30vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.background.out{
    animation: fadeout 0.7s;
}

.whitebar{
    position: absolute;
    width: 100vw;
    height: 1vmin;
    margin-left: -50vw;
    background-color: rgba(255, 255, 255, 0.274);
}

@keyframes slideinleft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideinright {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideoutleft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

@keyframes slideoutright {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.whitebar.top{
    top: 1vh;
    animation: slideinleft 0.5s;
}
.whitebar.top.out{
    animation: slideoutleft 0.5s;
    transform: translateX(-100%);
}

.whitebar.bottom{
    top: calc(30vh - 1vh - 1vmin);
    animation: slideinright 0.5s;
}
.whitebar.bottom.out{
    animation: slideoutright 0.5s;
    transform: translateX(100%);
}


h1{
    padding-top: 5vmin;
    font-size: 5vh;
}
h3{
    font-size: 3vh;
    margin-top: -3vh;
}

h1, h3{
    color: rgb(185, 185, 185);
    text-shadow: 0 0 1vmin rgba(49, 30, 0, 0.781);
}

</style>
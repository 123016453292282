<template>
    <div class="container" :style="{backgroundColor : props.color}" ref="container">

        <h1 class="cinzel-font ">{{ props.message }}</h1>
        <h3 class="cinzel-font ">{{ props.submessage }}</h3>
        <slot />
        <div class="whitebar top" ref="topbar">
            
        </div>
        <div class="whitebar bottom" ref="bottombar">
                
        </div>
    </div>

</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    message: String,
    submessage: String,
    color: String,
})

</script>

<style scoped>

.container{
    position: absolute;
    text-align: center;
    backdrop-filter: blur(5px);
    width: 100%;
}

.whitebar{
    position: absolute;
    width: 100%;
    height: 1vmin;
    background-color: rgba(255, 255, 255, 0.274);
}

.whitebar.top{
    top: 1vh;
}

.whitebar.bottom{
    bottom: 1vh;
}

h1{
    padding-top: 1vmin;
    font-size: 5vmin;
}
h3{
    font-size: 3vmin;
    margin-top: -3vmin;
}

h1, h3{
    color: rgb(185, 185, 185);
    text-shadow: 0 0 1vmin rgba(49, 30, 0, 0.781);
}

</style>
<template>
  <div id="side" :class="containerClasses">
    <PlayerProgress :compact="true"/>
    <div id="cardselection">
      <MessageBox message="Kartenauswahl" submessage="" color="rgba(54, 92, 0, 0.8)">
        <div id="cardcontainer">
          <div class="cardtoggle" v-for="card in gamestore.activeCards" :key="card.id" :class="{disabled: card.disabled}" @click="toggleCard(card.value)">
            <PlayingCard :value="card.value" :color="card.color" :opened="true" :shadow="!card.disabled" :interactive="gamestore.hostid === gamestore.selfid"/>
          </div>
        </div>
      </MessageBox>
    </div>
    <div id="startbtn" v-if="gamestore.hostid === gamestore.selfid" @click="startGame">
      <button class="button cinzel-font">Start</button>
    </div>
    <div id="startbtn" v-else>
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
  <div id="bottom">
    <JoinCode />
  </div>
  <div id="overlaycontainer">

    <LeaveWarning v-if="gamestore.leaveWarning"/>

  </div>

</template>

<script lang="ts" setup>
import { useGameStore } from '@/stores/gamestore';
import PlayerProgress from './components/UI/PlayerProgress.vue';
import JoinCode from './components/UI/JoinCode.vue';
import MessageBox from './components/UI/MessageBox.vue';
import PlayingCard from './components/card/PlayingCard.vue';
import LeaveWarning from './components/overlays/LeaveWarning.vue';
import { computed } from 'vue';

const gamestore = useGameStore();

setInterval(() => {
  gamestore.updateScreenDetails()
}, 1000)



const toggleCard = (card: any) => {
  if(gamestore.hostid === gamestore.selfid){
    gamestore.client.sendToggleCard(card);
  }
}

const startGame = () => {
  gamestore.client.sendStartRoom();
}

const containerClasses = computed(() => {
  return {
    touch: gamestore.touchDevice,
    vertical: gamestore.verticalScreen,
    over169: gamestore.over16to9,
    horizontal: !gamestore.verticalScreen
  };
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Noto+Color+Emoji&display=swap');

body {
  background-image: url('~@/assets/tactile_noise.png');
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  position: fixed;
}

#side{
  margin-top: 10vh;
  text-align: center;
}

#cardcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1vmin;
}

#startbtn{
  position: absolute;
  margin-top: calc(30vmin + 10vh);
  right: 1%;
  margin-right: 30%
}

#side.vertical #startbtn{
  margin-top: calc(5vh + 30vmin);
  margin-right: 10%
}

#side.vertical.over169 #startbtn{
  margin-top: calc(5vh + 30vmin);
  margin-right: 10%
}

.cardtoggle {
  transform: scale(0.5);
  margin-top: -6vmin;
  margin-left: -5vmin;
  margin-right: -5vmin;
}

.cardtoggle.disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  transform: translateY(2vmin) scale(0.5);
}

#overlaycontainer {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}



#cardselection {
  position: absolute;
  width: 200%;
  height: 30vmin;
  left: 50%;
  margin-top: 1vh;
  transform: translateX(-50%);
  text-align: center;
}

#cardselection.horizontal {
  transform: translateX(-50%) scale(0.7);
  justify-content: center;
  align-items: center;
}

.bottomcard{
  border-radius: 10px;
}

#trumpflabel {
  text-align: center;
  position: absolute;
  top: calc(30vh + 29vmin);
  font-weight: bold;
  left: calc(20.2vw + 10vmin) ;
  transform: translateX(-50%);
  font-size: 5vmin;
  color: rgba(150, 150, 150, 0.774);
  text-shadow: 0 0 1vmin rgba(49, 30, 0, 0.781);
}

#stichholder {
  position: absolute;
  width: 20vmin;
  height: 30vmin;
  top: calc(30vh + 5vmin);
  right:20.2vw;
}

#playercontainer {
  position: absolute;
  width: 100%;
  height: 30vmin;
  top: 17vh;
  left: 0;
  text-align: center;
}

#gamecontainer.vertical #playercontainer{
  position: absolute;
  width: 100%;
  height: 30vmin;
  top: 6vh;
  left: 0;
  text-align: center;
}

#gamecontainer.horizontal > #stichholder{
  right: 50vw;
  transform: translateX(50%);
}

.cardstaple {
  position: absolute;
  width: 20vmin;
  height: 30vmin;
  top: 30vh;
  left:20.2vw;
  transform: scale(0.7);
}

.cardstaple.a1{
  top: 29.5vh;
  left: 20vw;
}

.cardstaple.a2{
  top: 29vh;
  left: 19.8vw;
}

.cardstaple.a3{
  top: 28.5vh;
  left: 19.6vw;
}

.cardstaple.a4{
  top: 28vh;
  left: 19.4vw;
}

#gamecontainer{
  transform: scale(0.7);
  width: 100%;
  height: 100%;
  position: relative;
}

#gamecontainer.vertical{
  transform: scale(0.8) translateY(8vmin);
}

#gamecontainer.vertical.over169{
  transform: scale(1) translateY(8vmin);
}


#woodenbackground {
  position: absolute;
  overflow: hidden;
  transform: perspective(1000px) rotateX(15deg) scale(1.10);
  width: 100%;
  height: 80%;
  bottom: 40vmin;
}

#woodenbackground.vertical.over169 {
  bottom: 50vmin;
}

#woodenbackground::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
}

#woodenbackground::after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -30vmin;
  left: -50%;
  z-index: -1;
  background-position: 50% 40%;
  background-size: 90vw 90vw;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

#bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#bottom.vertical {
  align-items: center;
}


</style>

<template>
    <div class="side material-symbols-outlined" v-if="visibility" @click="toggleVisibility">
        visibility
    </div>
    <div v-else class="side material-symbols-outlined" @click="toggleVisibility">
        visibility_off
    </div>
    <div id="border" v-if="visibility">{{ gamestore.roomid }}</div>
    <div id="border" class="" v-else>••••</div>
    <div class="side material-symbols-outlined" @click="copyCode">content_copy</div>
</template>

<script lang="ts" setup>

import { ref } from 'vue';
import { useGameStore } from '@/stores/gamestore';

const gamestore = useGameStore();

const visibility = ref(true);

function toggleVisibility(){
    visibility.value = !visibility.value;
}

function copyCode(){
    if (navigator.clipboard)
        navigator.clipboard.writeText(gamestore.roomid);
}

</script>

<style scoped>

#border{
    height: 10vh;
    border: 0.6vh solid rgba(150, 150, 150, 0.774);
    font-family: monospace, monospace;
    color: rgba(218, 218, 218, 0.74);
    box-sizing: border-box;
    padding: 1vh;

    font-size: 7vh;
    font-weight: bold;
    line-height: 7vh;

    display: inline-block;
}

.side{
    bottom: 2vh;

    color: rgba(150, 150, 150, 0.774);
    
    padding: 1vh;

    font-size: 7vh;
    font-weight: bold;
    line-height: 7vh;

    display: inline-block;
    user-select: none;
    cursor: pointer;
}

.side:hover{
    color: rgba(128, 128, 128, 0.74);
}

.side:active{
    color: rgba(100, 100, 100, 0.582);
}

</style>
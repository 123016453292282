<template>
    <h1 id="createlabel"  @click="joinfunc?.apply">CREATE</h1>

    <div id="border" >
        <input ref="input" v-model="joincode" id="input" maxlength="4" placeholder="CODE"/>
    </div>
    <div class="side material-symbols-outlined" @click="joinfunc?.apply(null, [joincode.toUpperCase()])">rocket_launch</div>
</template>

<script lang="ts" setup>
import { ref, defineProps } from 'vue';
const joincode = ref("");

defineProps({
    joinfunc: Function
});

</script>

<style scoped>

#border{
    height: 10vh;
    border: 0.6vh solid rgba(255, 255, 255, 0.74);
    color: rgba(206, 159, 159, 0.74);
    box-sizing: border-box;
    padding: 1vh;

    width: 20vh;

    font-size: 7vh;
    font-weight: bold;
    line-height: 7vh;

}

#input{
    border: none;
    background-color: transparent;
    font-family: monospace, monospace;
    font-size: 7vh;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.74);
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    text-align: center;
    outline: none;
}

.side{
    bottom: 2vh;

    color: rgba(255, 255, 255, 0.74);
    
    padding: 1vh;

    font-size: 7vh;
    font-weight: bold;
    line-height: 7vh;

    user-select: none;
    cursor: pointer;
}

.side:hover{
    color: rgba(128, 128, 128, 0.74);
}

.side:active{
    color: rgba(100, 100, 100, 0.582);
}

#createlabel{
    font-family: monospace, monospace;
    font-size: 7vh;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.74);
    position: absolute;
    top: -20vh;
}

#placeholder{
    height: 10vh;
    width: 20vh;
}

</style>
<template>

  <head>
    <meta name=viewport content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  </head>

  <div id="tablebackground">

  </div>

  <div ref="content" class="content">
    <div ref="disconnected" v-if="currentScreen == 0">
      <DisconnectScreen />
    </div>

    <div ref="gameLobby" v-else-if="currentScreen == 1">
      <MainScreen />
    </div>

    <div ref="gameLobby2" v-else-if="currentScreen == 2">
      <LobbyScreen />
    </div>

    <div ref="gameRunning" v-else-if="currentScreen == 3">
      <GameScreen />
    </div>
  </div>

  <div id="leavebtn" v-if="currentScreen == 2 || currentScreen == 4">
    <button class="button red small material-symbols-outlined square" @click="leave">logout</button>
    <div id="topmiddle" v-if="currentScreen == 2 || currentScreen == 4">
      <h1 class="cinzel-font toptitle">Catana</h1><br>
    </div>
  </div>

  
</template>

<script lang="ts" setup>
import GameScreen from './GameScreen.vue';
import LobbyScreen from './LobbyScreen.vue';
import MainScreen from './MainScreen.vue';
import { useGameStore } from './stores/gamestore';
import DisconnectScreen from './DisconnectScreen.vue';
import { onMounted, ref } from 'vue';

const gamestore = useGameStore();
const content = ref();
gamestore.client.connect();

const currentScreen = ref(1);
const switchScreen = (screen: number) => {
  if (currentScreen.value == screen) return;
  content.value?.classList.add('out');
  setTimeout(() => {
    currentScreen.value = screen;
    content.value?.classList.remove('out');
    content.value?.classList.add('in');
    setTimeout(() => {
      content.value?.classList.remove('in');
    }, 250);
  }, 250);
}
gamestore.switchScreen = switchScreen;

const leave = () => {
  gamestore.leaveWarning = true;
}

onMounted(() => {
  gamestore.backgroundRef = content.value;
});



</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Noto+Color+Emoji&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@471&display=swap');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #2c3e50;
  
}

html {
  scroll-behavior: smooth;
}
body {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  position: fixed;
  touch-action: none;
  user-select: none;
}
#leavebtn {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  background-color: #0000005b;
  animation: dropIn 0.25s;
}

.content{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@keyframes in {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

.content.in {
  animation: in 0.25s;
}

.content.out {
  animation: out 0.25s;
}

.cinzel-font {
  font-family: "Cinzel", serif;
  font-optical-sizing: auto;
  font-style: normal;
  
}
.caveat-font {
  font-family: "Caveat", cursive;
  font-optical-sizing: auto;
  font-weight: 471;
  font-style: normal;
}
.noto-color emoji-regular {
  font-family: "Noto Color Emoji", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.material-symbols-outlined {
  font-variation-settings:'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.button {
  font-size: 3vh;
  padding: 0.5em 1em;
  border: 2px solid #000;
  border-radius: 2vh;
  background: radial-gradient(rgba(47, 167, 63, 0.767),rgba(14, 112, 27, 0.767));
  box-shadow: 0vmin 0vmin 1.5vmin rgba(46, 161, 61, 0.767);
  border: 1px solid rgba(44, 153, 58, 0.568);
  cursor: pointer;
  transition: all 0.1s;
  backdrop-filter: blur(10px);
  user-select: none;
  touch-action: none;
}

.button:hover {
  background: radial-gradient(rgba(60, 212, 81, 0.767),rgba(20, 158, 38, 0.767));
  box-shadow: 0vmin 0vmin 1.5vmin rgb(46, 161, 61);
}

.button:active {
  background: radial-gradient(rgba(60, 212, 81, 0.767),rgba(20, 158, 38, 0.767));
  box-shadow: 0vmin 0vmin 1.5vmin rgb(46, 161, 61);
  transform: scale(0.95);
}

.button.red {
  color: white;
  background: radial-gradient(rgba(167, 47, 47, 0.767),rgba(112, 14, 27, 0.767));
  box-shadow: 0vmin 0vmin 1.5vmin rgba(161, 46, 46, 0.767);
  border: 1px solid rgba(153, 44, 44, 0.568);
}

.button.small {
  font-size: 2vh;
  padding: 0.5em 1em;
  border-radius: 2vh;
}

.button.square {
  border-radius: 0;
}

.button.white {
  color: black;
  background: radial-gradient(rgba(209, 192, 237, 0.35), rgba(220, 213, 232, 0.35));
  box-shadow: 0vmin 0vmin 3vmin rgba(131, 131, 131, 0.377);
  border: 2px solid rgba(126, 126, 126, 0.555);
}

.button.white:hover {
  background: radial-gradient(rgba(220, 213, 232, 0.35), rgba(231, 224, 243, 0.35));
  box-shadow: 0vmin 0vmin 3vmin rgba(131, 131, 131, 0.377);
  border: 2px solid rgba(126, 126, 126, 0.555);
}

.button.white:active {
  background: radial-gradient(rgba(220, 213, 232, 0.35), rgba(231, 224, 243, 0.35));
  box-shadow: 0vmin 0vmin 3vmin rgba(131, 131, 131, 0.377);
  border: 2px solid rgba(126, 126, 126, 0.555);
  transform: scale(0.95);
}

#tablebackground {
  position: absolute;
  overflow: hidden;
  transform: perspective(1000px) rotateX(15deg) scale(1.10);
  width: 110%;
  left: -5%;
  height: 110%;
  bottom: 0;
  box-shadow: 0 0 20vmin rgba(0, 0, 0, 1);
}

#tablebackground::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  background: url('~@/assets/moroccan-flower-dark.webp') 0 0 repeat;
  background-size: 50vmin;
}

#tablebackground::after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -30vmin;
  left: -50%;
  z-index: -1;
  transition-duration: 2s;
  background-position: 50% 40%;
  background-size: 90vw 90vw;
  animation: colorChange 100s infinite;
  background-blend-mode: multiply;
}

@keyframes colorChange {
  0% {
    background-color: rgba(179, 147, 2, 0.356);
  }
  25% {
    background-color: rgba(6, 105, 2, 0.418);
  }
  50% {
    background-color: rgba(0, 21, 141, 0.356);
  }
  75% {
    background-color: rgba(179, 2, 2, 0.356);
  }
  100% {
    background-color: rgba(179, 147, 2, 0.356);
  }
}

@keyframes dropIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

#topmiddle {
  position: absolute;
  top: 0;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

h1.toptitle {
  font-size: 6vh;
  margin-top: 10vh;
  margin: 0;
  padding: 0;
  color: rgba(233, 213, 183, 0.774);
  text-shadow: 0 0 1vmin rgba(128, 111, 84, 0.781);
}


</style>

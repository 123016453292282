<template>
    <div id="container" :class="{current : props.player.id === gamestore.currentuserid, golden: props.golden, disconnected: props.disconnected, vertical: gamestore.verticalScreen}">
        <span v-if="props.player.id === gamestore.hostid">⭐&nbsp;</span>
        <span v-if="props.disconnected" class="material-symbols-outlined">signal_disconnected</span>
        {{  props.player.name }}<br v-if="!props.compact">
        

        <div class="noto-color b" v-if="!props.compact">
            <span class="greenemoji">
                {{ props.player.currentStiche }} ✓
            </span>
            •
            <span class="greenemoji">
                {{ props.player.predictedStiche }} 📣  
            </span>
            <span v-if="player.id === gamestore.currentBeginner" class="greenemoji noto-color">
                <span class="b">•</span> 🏃‍♂️
            </span>
        </div>

    </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useGameStore } from '@/stores/gamestore';
import Player from '@/types/player'

const gamestore = useGameStore();

const props = defineProps({
    player: Player,
    compact: Boolean,
    golden: Boolean,
    disconnected: Boolean
})

</script>

<style scoped>

div {
    color: white; 
}

#gamecontainer.vertical #container{
    font-size: 3vmin;
}

#container{
    padding-left: 3vmin;
    padding-right: 3vmin;
    padding-top: 1vmin;
    padding-bottom: 1vmin;
    font-size: 2vmin;
    text-align: center;
    font-weight: 500;

    background: radial-gradient(rgba(209, 192, 237, 0.35), rgba(220, 213, 232, 0.35));
    backdrop-filter: blur(10px);    
    color: black;

    max-width: 28vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 1.5vmin;

    margin: 0.5vw;
}

#container.vertical{
    font-size: 4vmin;
}

#container.current{
    background: radial-gradient(rgba(47, 167, 63, 0.767),rgba(14, 112, 27, 0.767));
    border: 1px solid rgba(44, 153, 58, 0.568);
    box-shadow: 0vmin 0vmin 1.5vmin rgba(46, 161, 61, 0.767);
}

#container.golden{
    transform: scale(1.2);
    box-shadow: 0vmin 0vmin 3vmin rgba(0, 0, 0, 0.377);
    margin-left: 2vmin;
    margin-right: 2vmin;
    z-index: 5;
}

#container.disconnected{
    border: 1px solid rgba(204, 18, 18, 0.568);
}

.b{
    color: rgb(58, 58, 58);
}

#container.current .greenemoji{
    color: transparent;  
    text-shadow: 0 0 0 rgba(37, 37, 37, 0.7);
}

.greenemoji{
    color: transparent;  
    text-shadow: 0 0 0 rgba(7, 83, 7, 0.7);
}

</style>
<template>
    <table>
        <thead>
            <tr>
                <th class="noborder"></th>
                <th v-for="user in users" :key="user" class="cinzel-font top"><span class="rotated">{{ gamestore.getPlayerById(user)?.name }}</span></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="round in maxRounds" :key="round">
                <td class="cinzel-font bold">{{ round }}</td>
    
                <td v-for="user in users" :key="user + '2'" class="caveat-font" >
                    <div v-if="gamestore.pointTable[round] !== undefined" >
                        <span v-if="gamestore.pointTable[round][user] !== undefined && gamestore.pointTable[round][user][1] <= 0" class="red">
                            {{ gamestore.pointTable[round][user][0] || 0 }}
                        </span>
                        <span v-if="gamestore.pointTable[round][user] !== undefined && gamestore.pointTable[round][user][1] > 0" class="green">
                            {{ gamestore.pointTable[round][user][0] || 0 }}
                        </span>
                        <br>
                        <span v-if="gamestore.pointTable[round][user] !== undefined" class="bold">
                            {{ gamestore.pointTable[round][user][1] }}
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
import { useGameStore } from '@/stores/gamestore';
const gamestore = useGameStore();

const users = gamestore.players.map(user => user.id);


const maxRounds = 16;

</script>

<style scoped>
    table {
        border-collapse: collapse;
        color:black;
        width: 100%;
        table-layout: fixed;
        margin-bottom: 25vh;
    }

    th, td {
        border: 1.5px dashed rgba(0, 0, 0, 0.692);
        
        text-align: center;
        font-size: 1.2em;
        border-right: none;
        border-bottom: none;
        border-top: none;
    }

    th{
        border: none;
        background-color: transparent;
        text-align: left;
    }

    td.bold{
        font-weight: bold;
        padding: 8px;
        font-size: 1.25em;
        border-left: none;
    }

    tr:nth-child(even) {
        background-color: rgba(133, 133, 133, 0.267);
    }

    span.rotated {
        display: block;
        transform: rotate(-80deg);
        padding: 8px;
        font-size: 1em;
    }
    
    span.bold{
        font-weight: bold;
        font-size: 1.25em;
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }

    th.noborder {
        border: none;
        background-color: transparent ;
    }

    th.top{
        border-top: none;
    }

    th {
        padding: 8px;
        font-size: 1em;
    }

    th:first-child {
        text-align: left;
    }
</style>
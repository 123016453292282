<template>
    <div>
        <button class="button material-symbols-outlined" v-if="props.button" @click="useGameStore().client.sendLayCard(cardid)">place_item</button>

        <div :cardid="cardid" ref="border" :class="(isOpened?props.color:'closed') + ' border ' + props.value + ' ' + (props.interactive?'interactive':'') + ' ' + (props.shadow?'shadow':'')">

            <!-- Add number to the top left -->
            <div id="top-left" :class="{'cinzel-font' : true , dark : props.value === 'P' || props.value === 'F' || props.value === 'J' || props.value === 'W'}" v-if="isOpened">
                {{ 
                    props.value === 'D' ? 'D↑' :
                    props.value === 'F' ? 'F↓' :
                    props.value === 'W' ? '⇄' :
                    props.value === 'O' ? '0' : 
                    props.value === 'C' ? '9' :
                    props.value === 'J' ? '7' :
                    props.value === 'OP' ? '0☁️' : props.value }}
                <span class="threequater" v-if="props.value === 'C' || props.value === 'J'">¾</span>
            </div>
            <div id="bottom-right" class="cinzel-font dark" v-if="isOpened">
                {{ props.value === 'OP' ? '✨P' : '' }}
            </div>
            <div :class="{ emojiwrapper: true, bright: props.value === 'O' || props.value === 'C' || props.value === 'B', dark: props.value === 'P' }" v-if="isOpened">
                <div class="emoji">
                    {{ 
                        props.value === 'W' ? '🐺' :
                        props.value === 'J' ? '♻️' :
                        props.value === 'D' ? '🐉' :
                        props.value === 'F' ? '🧚' :
                        props.value === 'B' ? '💣' :
                        props.value === 'P' ? '✨' :
                        props.value == 'O' ? '☁️' :
                        props.value === 'C' ? '💡' :
                        props.value === 'OP' ? '' :
                        props.color === 'red' ? '🧙' : 
                        props.color === 'blue' ? '👮' : 
                        props.color === 'green' ? '🥷' : 
                        props.color === 'yellow' ? '🧝‍♂️' : '☁️'}}
                </div>
                <div id="treeemoji">
                    {{ 
                        props.value === 'W' ? '' :
                        props.value === 'J' ? '' :
                        props.value === 'D' ? '🐉' :
                        props.value === 'F' ? '🧚' :
                        props.value === 'B' ? '' :
                        props.value === 'C' ? '' :
                        props.value === 'P' ? '' :
                        props.value === 'OP' ? '' :
                        props.color === 'red' ? '🌲' : 
                        props.color === 'blue' ? '🌳' :
                        props.color === 'green' ? '🌴' : 
                        props.color === 'yellow' ? '🌵' : ''}}
                </div>
                <div id="moonemoji">
                    {{ 
                        props.color === 'red' ? '♥️' : 
                        props.color === 'blue' ? '♠️' : 
                        props.color === 'green' ? '♦️' : 
                        props.color === 'yellow' ? '♣️' : ''}}
                </div>
            </div>
            <div v-else class="emojiwrapper light">
                <div id="emojicenter">
                    ⚔️
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useGameStore } from '@/stores/gamestore';
import { defineProps, defineExpose, ref, onMounted } from 'vue';
// get props
const props = defineProps<{
    opened?: boolean,
    color?: string,
    value?: string | number,
    shadow?: boolean,
    interactive?: boolean,
    cardid?: number,
    button?: boolean
}>()

const border = ref();
const isOpened = ref(props.opened);
const cardid = ref(props.cardid);

function rotateCard(){
    // add class to rotate card
    border.value.classList.add('rotate');

    // after 0.5s
    setTimeout(() => {
        isOpened.value = !isOpened.value;
        setTimeout(() => {
            if (border.value === null) return;
            border.value.classList.add('rotateoff');
            setTimeout(() => {
                if (border.value === null) return;
                border.value.classList.remove('rotateoff');
                border.value.classList.remove('rotate');
            }, 200);
        }, 1);
    }, 200);
}

onMounted(() => {
    //rotateCard();
});


defineExpose({
    rotateCard,
    cardid
})
</script>

<style scoped>

@keyframes rotate {
  0% { transform: perspective(1000px) }
  100% { transform: perspective(1000px) rotateX(20deg) scale(1.10) rotateY(30deg) translateY(-10vmin); opacity: 0.0; }
}

@keyframes rotateoff {
  0% { transform: perspective(1000px) rotateX(20deg) scale(1.10) rotateY(30deg) translateY(-10vmin); opacity: 0.0; }
  100% { transform: perspective(1000px) rotateY(0deg); opacity: 1;}
}

@keyframes in {
    0% { 
        height: 0vh;
        opacity: 0;
        font-size: 0vh; 
    }
    100% { 
        height: 6vh;
        opacity: 1; 
    }
}

.button{
    width: 100%;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 3vh;
    height: 6vh;
    animation: in 0.2s;
}

.border.rotate{
    animation: rotate 0.2s ease-in;
}

.border.rotateoff{
    animation: rotateoff 0.2s ease-out;
}

.border {

    outline: 1px solid transparent;
    position: relative;
    border: 0.4vmin solid rgb(63, 61, 57);
    background-color: rgb(63, 61, 57);
    
    border-radius: 10px;

    width: 20vmin;
    height: 30vmin;

    overflow: hidden;
    user-select: none;

    /* Add 3D lift effect */
    transform: perspective(1000px) rotateX(0deg);

}

.border.shadow {
    box-shadow: -1vmin 1vmin 1vmin rgba(0, 0, 0, 0.2);
}

.border.interactive:hover {
    /* Apply the lift effect on hover */
    transform: perspective(1000px) rotateX(-5deg) scale(1.10);
    z-index: 1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.emoji {
    font-size: 14vmin;
    text-align: center;

    transform: translate(3vmin, 15vmin) ;
    color: transparent;  
    text-shadow: 0 0 0 rgba(0, 0, 0, 1);

}

div.red {
    background: radial-gradient(rgba(115, 20, 5 , 1.00010), rgba(156, 31, 9,  1.000));
    background-position: 15vmin 7vmin;
}

div.blue {
    background: radial-gradient(rgba(5, 20, 115, 1.000), rgba(9, 31, 156, 1.000));
    background-position: 15vmin 7vmin;
}

div.green {
    background: radial-gradient(rgba(20, 115, 5, 1.000), rgba(31, 156, 9, 1.000));
    background-position: 15vmin 7vmin;
}

div.yellow {
    background: radial-gradient(rgba(115, 115, 5, 1.000), rgba(156, 156, 9, 1.000));
    background-position: 15vmin 7vmin;
}

div.none.P {
    background: radial-gradient(rgba(209, 192, 237, 1.000), rgba(220, 213, 232, 1.000));
    background-position: 15vmin 7vmin;
}

div.none.W {
    background: radial-gradient(rgba(98, 102, 99, 1.000), rgba(127, 130, 128, 1.000));
    background-position: 15vmin 7vmin;
}

div.none.C {
    background-image: radial-gradient(
        circle at 13vmin 20vmin,
        rgba(0,0,0,1.000) 20%,
        rgba(0,0,0,1.000)  40%,
        rgba(228, 206, 10, 1.000) 40%,
        rgba(228, 206, 10, 1.000) 60%,
        rgba(0,0,0,1.000)  60%,
        rgba(0,0,0,1.000)  87%,
        rgba(228, 206, 10, 1.000) 87%,
        rgba(228, 206, 10, 1.000) 100%
        );
    background-repeat: no-repeat;
}

div.none.B {
    background-image: radial-gradient(
        circle at 13vmin 25vmin,
        rgba(0,0,0,1.000) 20%,
        rgba(0,0,0,1.000) 40%,
        rgba(228, 10, 10, 1.000) 40%,
        rgba(228, 10, 10, 1.000) 60%,
        rgba(0,0,0,1.000) 60%,
        rgba(0,0,0,1.000) 80%,
        rgba(228, 10, 10, 1.000) 80%,
        rgba(228, 10, 10, 1.000) 100%
        );
}

div.none.J {
    background: rgba(255,255,255,1) radial-gradient(
        circle at 13vmin 23.5vmin,

        rgba(79, 220, 74, 0.4) 30%,
        rgba(63, 218, 216, 0.4) 40%,
        rgba(47, 201, 226, 0.4) 50%,
        rgba(28, 127, 238, 0.4) 60%,
        rgba(95, 21, 242, 0.4) 70%,
        rgba(186, 12, 248, 0.4) 80%,
        rgba(251, 7, 217, 0.4) 90%,
        rgba(255, 0, 0, 0.4) 100%
        );
}

div.none.O {
    background: radial-gradient(rgba(17, 1, 43, 1.000), rgba(19, 7, 38, 1.000));
    background-position: 15vmin 7vmin;
}

div.none.D {
    background: linear-gradient(-225deg, rgba(228, 10, 10, 1.000), rgba(228, 10, 10, 1.000) 50%, rgb(220, 213, 232, 1.000) 50%, rgb(220, 213, 232,  1.000));
}

div.none.F {
    background: linear-gradient(-225deg, rgb(220, 213, 232,  1.000), rgb(220, 213, 232,  1.000) 50%, rgb(123, 134, 192, 1.000) 50%, rgb(123, 134, 192, 1.000));
}

div.none.OP {
    background: linear-gradient(-225deg, rgb(17, 1, 43, 1.000), rgb(17, 1, 43, 1.000) 50%, rgb(220, 213, 232, 1.000) 50%, rgb(220, 213, 232, 1.000));
}

div.closed {
    background: radial-gradient(rgb(46, 45, 38), rgb(31, 31, 31));
    background-position: 15vmin 7vmin;
}

#top-left {
    position: absolute;
    color: rgba(255, 255, 255, 1);
    font-size: 7vmin;
    margin-top: 0.3vmin;
    margin-left: 1.7vmin;
    z-index: 1;
    color: transparent;
    text-shadow: 0 0 0 white;
}

#bottom-right {
    position: absolute;
    color: transparent;
    font-size: 7vmin;
    bottom: 0vmin;
    right: 1.7vmin;
    z-index: 1;
}

.dark {
    text-shadow: 0 0 0 rgba(0, 0, 0, 1) !important;
}

#treeemoji {
    position: absolute;

    font-size: 30vmin;
    text-align: center;
    bottom: -8vmin;
    left: -18vmin;  
    color: transparent;  
    text-shadow: 0 0 0 rgba(0, 0, 0, 0.7);
}

#moonemoji {
    position: absolute;
    font-size: 4vmin;
    top: 2vmin;
    right: 2vmin;
    text-align: center;
    color: transparent;  
    text-shadow: 0 0 0 rgba(0, 0, 0, 0.7);
}

#emojicenter {
    font-size: 10vmin;
    text-align: center;
    color: transparent;  
    margin-top: 7vmin;
    text-shadow: 0 0 0 rgba(17, 17, 17, 0.555);
}

.threequater {
    position: absolute;
    font-size: 5vmin;
    top: 0.5vmin;
    left: 4vmin;
}

.emojiwrapper {
    opacity: 0.5;
}

.emojiwrapper.light {
    filter: invert(1);
    opacity: 0.2;
}

.emojiwrapper.bright {
    filter: invert(1);
    opacity: 1;
}

.emojiwrapper.dark {
    opacity: 1;
}

</style>
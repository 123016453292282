<template>
    <div class="stich">

        <div v-for="card in gamestore.stich" :key="card.value + card.color + 'z'" class="card">
            <div :class="{first : card === gamestore.stich[0], currentwinner : card.highest, left: gamestore.stich.indexOf(card) % 2 == 0, right: gamestore.stich.indexOf(card) % 2 == 1, owncard: card.playerid == gamestore.selfid, animatedown: gamestore.stich.indexOf(card) == gamestore.stich.length-1}">
                <PlayingCard :value="card.value" :color="card.color" :opened="true" :interactive="false" :shadow="true" :animatedown="gamestore.stich.indexOf(card) == gamestore.stich.length-1"/>
                <div class="crown" v-if="card.highest">
                    👑
                </div>

                <div class="name cinzel-font" v-if="card.playerid != null && gamestore.getPlayerById(card.playerid) != null">
                    {{ gamestore.getPlayerById(card.playerid).name }}
                </div>
            </div>
        </div>

        <div id="label" class="cinzel-font">
          Stich
        </div>
    
    </div>
</template>

<script setup>
    import { useGameStore } from '@/stores/gamestore';
    import PlayingCard from './PlayingCard.vue';

    const gamestore = useGameStore();
</script>

<style scoped>


.stich{
    padding-left: 0vmin;
    padding-top: 15vmin;
}

.vertical .stich{
    padding-left: 0vmin;
    padding-top: 10vmin;
    margin-top: -3vmin;
    position: absolute;
}

.card{
    margin-top: -25vmin;
    transform: scale(0.8);
}

.left{
    transform: rotate(1deg); 
}

.right{
    transform: rotate(1deg);
}

.first{
    transform: rotate(-15deg) translateX(-5vmin);
}

.currentwinner{
    transform: rotate(-15deg) translateX(-5vmin);
}

.crown{
    position: absolute;
    top: -6vmin;
    left: -3vmin;
    transform: rotate(-20deg);
    font-size: 7vmin;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0 -1vmin 1vmin rgba(49, 30, 0, 0.781);

}

.name{
    position: absolute;
    top: 2vmin;
    left: 18vmin;
    width: 100%;
    text-align: center;
    font-size: 3vmin;
    color: rgba(255, 255, 255, 0.774);
    text-shadow: 0 -1vmin 1vmin rgba(49, 30, 0, 0.781);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.currentwinner .name{
    top: 2vmin;
    text-align: right;
    left: calc(-100% - 1.5vmin);
}

.first .name{
    top: 2vmin;
    text-align: right;
    left: calc(-100% - 1.5vmin);
}

#label {
  text-align: center;
  font-weight: bold;
  font-size: 5vmin;
  color: rgba(150, 150, 150, 0.774);
  text-shadow: 0 0 1vmin rgba(49, 30, 0, 0.781);
}

@keyframes animatedown {
  0% { transform: translateY(-50vmin) scale(1.2); opacity: 0; }
  100% { transform: translateY(0vmin); }
}

.animatedown {
    animation: animatedown 0.5s ease-in-out;
}

@keyframes animatedownw {
  0% { transform: translateY(-50vmin) scale(1.2); opacity: 0; }
  100% { transform: translateY(0vmin) rotate(-15deg) translateX(-5vmin); }
}

.currentwinner.animatedown {
    animation: animatedownw 0.5s ease-in-out;
}

@keyframes animatedownself {
  0% { transform: translateY(50vmin) scale(1.2); opacity: 0; }
  100% { transform: translateY(0vmin); }
}

.animatedown.owncard {
    animation: animatedownself 0.5s ease-in-out;
}

@keyframes animatedownwself {
  0% { transform: translateY(50vmin) scale(1.2); opacity: 0; }
  100% { transform: translateY(0vmin) rotate(-15deg) translateX(-5vmin); }
}

.currentwinner.animatedown.owncard {
    animation: animatedownwself 0.5s ease-in-out;
}

</style>
<template>
    <div class="cardholder" ref="cardholder"> 
        <div id="wrapper" :class="containerClasses">
            <div class="placeholder"></div>
            <div :id="card.id + 'cc'" v-for="card in gamestore.cards" :key="card.value + card.color" :class="{card: true, disabled : (card.disabled && gamestore.currentuserid == gamestore.selfid), selected: card.id == selectedId, flyout: (card as any)._laying}">
                <PlayingCard :id="card.id + 'c'" :value="card.value" :color="card.color" :opened="true" :button="card.id == selectedId && gamestore.selfid == gamestore.currentuserid && !gamestore.animating && !gamestore.predicting" :cardid="card.id" :interactive="false" @click="selectThis(card.id)"/>
            </div>
            <div class="placeholder right"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useGameStore } from '@/stores/gamestore';
    import PlayingCard from './PlayingCard.vue';
    import { onMounted, ref, onBeforeUnmount } from 'vue';
    import { computed } from 'vue';

    const gamestore = useGameStore();

    const cardholder = ref();

    const interval = ref();

    const selectedId = ref(-1);

    onMounted(() => {
        // get card in the middle
        let card = gamestore.cards[Math.floor(gamestore.cards.length / 2)];
        if (!card) return;
        let cardWrapper = document.getElementById(card.id + 'cc');
        if (!cardWrapper) return;
        cardholder.value.scrollLeft = (cardWrapper.offsetLeft) - window.innerWidth / 2 + 30;
    });

    onBeforeUnmount(() => {
        clearInterval(interval.value);
    });

    function selectThis(id : number) {
        selectedId.value = id;
        let element = document.getElementById(id + 'c');
        let cardWrapper = document.getElementById(id + 'cc');
        const scrollingContainer = document.getElementById('wrapper');
        if (!element) return;
        if (!cardWrapper) return;
        if (!scrollingContainer) return;
        cardholder.value.scrollTo({ left: (cardWrapper.offsetLeft) - window.innerWidth / 2, behavior: 'smooth' })
        //gamestore.client.sendLayCard(id);
    }

    // const onScroll = () => {
    //     if (!gamestore.touchDevice) return;
    //     const scrollLeft = cardholder.value.scrollLeft;
    //     // get cardWrapper where (cardWrapper.offsetLeft) - window.innerWidth / 2 is closest to 0
    //     let closest = Infinity;
    //     let closestId = -1;
    //     for (let card of gamestore.cards) {
    //         let cardWrapper = document.getElementById(card.id + 'cc');
    //         if (!cardWrapper) continue;
    //         let distance = Math.abs((cardWrapper.offsetLeft) - window.innerWidth / 2 - scrollLeft);
    //         if (distance < closest) {
    //             closest = distance;
    //             closestId = card.id;
    //         }
    //     }
    //     if (closestId != -1 && closestId != selectedId.value) {
    //         selectedId.value = closestId;
    //     }
    // }

    const containerClasses = computed(() => {
        return {
            touch: gamestore.touchDevice,
            vertical: gamestore.verticalScreen,
            over169: gamestore.over16to9,
            horizontal: !gamestore.verticalScreen
        };
    });

</script>

<style scoped>

.horizontal .button{
    margin-top: 10vmin;
}

.vertical .button{
    margin-top: 8vmin;
}

.vertical.over169 .button{
    margin-top: 0vmin;
}

.button{
    font-size: 5vmin !important;
}


.bcontainer{
    position: absolute;
    text-align: center;
    position: absolute;
    width: 100vw;
    top: -35vmin;
    z-index: 10;
}

.cardholder{
    overflow-x: scroll;
    overflow-y: clip;
    white-space: nowrap;
    text-align: center;
    width:100%;
    touch-action: pan-x;
    scroll-snap-align: center;
    padding-left: 25vmin;
    padding-right: 25vmin;
}

.card {
    margin: 2vmin;
    margin-right: -5vmin;
    margin-left: -5vmin;
    margin-top: 9vmin;
    display: inline-block;
    position: relative;
    transition: transform 0.2s, margin-right 0.2s, z-index 0.2s, box-shadow 0.2s;
    transform: scale(1.1);
    border-radius: 10px;
    transform: perspective(1000px) rotateX(0deg) rotateY(10deg)  scale(0.8);
    box-shadow: -1vmin 0 1vmin rgba(0, 0, 0, 0.5);
}

#wrapper.vertical .card {
    transform: perspective(1000px) rotateX(0deg) rotateY(10deg) scale(1.0);
    margin-right: -3.5vmin;
    margin-left: -3.5vmin;
}

#wrapper.vertical .card.selected {
    transform: perspective(1000px) rotateY(-5deg) scale(1.0) translateY(-3vmin) translateX(-7vmin);
    margin-right: -3.5vmin;
    margin-left: -3.5vmin;
}

#wrapper.vertical.over169 .card {
    transform: perspective(1000px) rotateX(0deg) rotateY(10deg) scale(1.1);
    margin-right: -3.5vmin;
    margin-left: -3.5vmin;
}

#wrapper.vertical.over169 .card.selected {
    transform: perspective(1000px) rotateY(-5deg) scale(1.2) translateY(-3vmin) translateX(-7vmin);
    margin-right: -3.5vmin;
    margin-left: -3.5vmin;
}

.card.disabled{
    pointer-events: none;
    filter: grayscale(50%) brightness(50%);
    bottom: -1vmin;
    opacity: 1;
}

.card:hover , .card:active{
    /* Apply the lift effect on hover */
    transform: perspective(1000px) rotateY(-5deg) scale(0.9) translateY(-5vmin) translateX(-7vmin);
    z-index: 1;
    margin-right: -7vmin;
    box-shadow: 1vmin 1vmin 4vmin rgba(0, 0, 0, 1);
}

#wrapper.vertical.over169 .card:hover {
    transform: perspective(1000px) rotateY(-5deg) scale(1.2) translateY(-2vmin) translateX(-7vmin);
}


.card:active{
    transform: perspective(1000px) rotateY(0deg) rotateX(8deg) scale(0.85) translateY(-9vmin) translateX(-7vmin);
}

#wrapper.vertical.over169 .card:active {
    transform: perspective(1000px) rotateY(0deg) rotateX(8deg) scale(1.1) translateY(-5vmin) translateX(-7vmin);
}

.card.selected{
    transform: perspective(1000px) rotateY(-5deg) scale(0.9) translateY(-5vmin) translateX(-7vmin);
    z-index: 1;
    margin-right: -7vmin;
    box-shadow: 1vmin 1vmin 4vmin rgba(0, 0, 0, 1);
}


.placeholder {
    display: inline-block;
    width: calc(60%);
    height: 1vw;
}

.placeholder.right {
    width: calc(60% + 10vh);
}

@keyframes flyout {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
        transform: translateY(-10vmin) translateX(-7vmin);

    }
    100% {
        opacity: 0;
    }
}
.card.flyout {
    animation: flyout 0.5s;
}


</style>
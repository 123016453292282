import { defineStore,  } from 'pinia'
import { ref, Ref, watch } from 'vue'
import { Card } from '@/types/card'
import { Player } from '@/types/player'
import { Client } from '@/client'
import { GameState } from '@/types/gamestate'

export const useGameStore = defineStore('game', () => {

    const client = new Client();

    const round = ref(0);
    const currentuserid = ref(0);
    const predicting = ref(true);


    const cards: Ref<Card[]> = ref([])
    const stich: Ref<Card[]> = ref([])
    const players: Ref<Player[]> = ref([])
    const activeCards = ref<Card[]>([]);
    const trumpfCard = ref<Card | null>(null);


    // -----------------------------------------------------------

    const getPlayerById = (id: number) => {
        return players.value.find(player => player.id === id)
    }

    // Device and Reactitivity Functions
    const isTouchDevice = () => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0));
    }

    const isVerticalScreen = () => {
        return window.innerHeight > window.innerWidth
    }

    const isOver16to9 = () => {
        if (isVerticalScreen()) 
            return window.innerHeight / window.innerWidth >= 16 / 9
        return window.innerWidth / window.innerHeight >= 16 / 9
    }
    const touchDevice = ref(isTouchDevice())
    const verticalScreen = ref(isVerticalScreen())
    const over16to9 = ref(isOver16to9())

    function updateScreenDetails() {
        touchDevice.value = isTouchDevice()
        verticalScreen.value = isVerticalScreen()
        over16to9.value = isOver16to9()
    }

    const backgroundRef = ref();
    const switchScreen = ref<Function | null>(null);
    const roomid = ref("");

    // General Game Details
    const gamestate = ref<GameState | null>(null);
    const hostid = ref<number | null>(null);
    const selfid = ref<number | null>(null);

    watch(gamestate, (newVal) => {
        if (newVal === GameState.None) {
            switchScreen.value?.(1);
            navigateTo('home');
            leaveWarning.value = false;
        }
        if (newVal === GameState.NotStarted) {
            switchScreen.value?.(2);
            navigateTo('lobby');
            leaveWarning.value = false;
        }
        if (newVal === GameState.InProgress) {
            switchScreen.value?.(3);
            navigateTo('game');
            leaveWarning.value = false;
        }
        if (newVal === GameState.Ended) {
            switchScreen.value?.(4);
            navigateTo('end');
            leaveWarning.value = false;
        }

    });

    function navigateTo(page : string) {
        const state = { page: page };
        const title = '';
        const url = `#${page}`;
        if (history.state && history.state.page === page) {
            return;
        }
        history.pushState(state, title, url);
    }

    const leaveWarning = ref(false);

    window.onpopstate = function(event) {
        if (gamestate.value !== GameState.None) {
            leaveWarning.value = true;
        }
    };

    // In Game State Information
    const currentAnnouncer = ref(0);
    const currentBeginner = ref(1);
    const canLayCard = () => {
        // random true or false
        return Math.random() > 0.5
    }

    const rotateTrumpf = ref(false);
    const animating = ref(false);

    function $reset() {
        round.value = 0;
        currentuserid.value = 0;
        predicting.value = true;
        cards.value = [];
        stich.value = [];
        players.value = [];
        activeCards.value = [];
        trumpfCard.value = null;
        gamestate.value = null;
        hostid.value = null;
        selfid.value = null;
        currentAnnouncer.value = 0;
        currentBeginner.value = 1;
    }
    
    const disconnectReason = ref('');

    const pointTable = ref<{ [round: number]: { [user: number]: number } }>({});



    return {$reset, roomid, pointTable, leaveWarning,switchScreen, backgroundRef, disconnectReason, animating, rotateTrumpf, trumpfCard, currentuserid, predicting, round, client, gamestate, hostid, selfid, cards, stich, players, activeCards, canLayCard, touchDevice, verticalScreen, over16to9, updateScreenDetails, currentAnnouncer, currentBeginner, getPlayerById }
})

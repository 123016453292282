import { io } from "socket.io-client";
import { useGameStore } from "./stores/gamestore";
import { GameState } from "./types/gamestate";
import { reactive } from "vue";


export class Client {
    public socket: any;

    public state: {
        bannerEvents: any[];
        barEvents: any[];
    } = reactive({
        bannerEvents: [],
        barEvents: []
    });

    constructor() {
       
    }

    public connect() {
        const host = process.env.VUE_APP_HOST || "localhost";
        const port = process.env.VUE_APP_PORT || 8988;

        const gameStore = useGameStore();

        if (process.env.VUE_APP_SSL == "1") 
            this.socket = io(`https://${host}:${port}`);
        else
            this.socket = io(`http://${host}:${port}`);

        this.socket.on("connect", () => {
            console.log("connected");
            gameStore.switchScreen?.(1);
        });

        this.socket.on("disconnect", () => {
            gameStore.$reset();
            console.log("disconnected");
            gameStore.switchScreen?.(0);
            gameStore.disconnectReason = "Disconnected from server";
        });

        const socket = this.socket;

        window.onbeforeunload = function(e) {
            if (gameStore.gamestate !== GameState.None){
                return 'Are you sure you want to leave?';
            }
            socket.disconnect();
        };

        //listen to "store"
        this.socket.on("store", (data: any) => {
            // Merge data into store

            if (data.pointTable != null) gameStore.pointTable = data.pointTable;
            if (data.roomid != null) gameStore.roomid = data.roomid;
            if (data.gamestate != null) gameStore.gamestate = data.gamestate;
            if (data.players) gameStore.players = (data.players);
            if (data.round != null) gameStore.round = data.round;
            if (data.hostid != null) gameStore.hostid = data.hostid;
            if (data.selfid != null) gameStore.selfid = data.selfid;
            if (data.activeCards) gameStore.activeCards = data.activeCards;
            if (data.handCards) gameStore.cards = data.handCards;
            if (data.currentuserid !== null) gameStore.currentuserid = data.currentuserid;
            if (data.predicting != null) gameStore.predicting = data.predicting;
            if (data.stich) gameStore.stich = data.stich;
            if (data.trumpfCard != null) gameStore.trumpfCard = data.trumpfCard;
            if (data.animating != null) gameStore.animating = data.animating;
        });

        this.socket.on("bannermsg", (...args: any) => {
            this.state.bannerEvents.push(args);
        });

        this.socket.on("sessionID", (sessionID: string) => {
            sessionStorage.setItem("sessionID", sessionID);
            localStorage.setItem("sessionID", sessionID);

            console.log("received sessionID", sessionID);
        });

        this.socket.on("rotateTrumpf", () => {
            gameStore.rotateTrumpf = true;
        });

        if (this.socket.connected) {
            this.socket.disconnect();
        }
        const sessionID = sessionStorage.getItem("sessionID") || localStorage.getItem("sessionID");
        const username = sessionStorage.getItem("username") || localStorage.getItem("username");

        console.log("sessionID", sessionID);

        this.socket.auth = { sessionID: sessionID, username: username };

        this.socket.connect();
    }

    public isConnected() {
        return this.socket.connected;
    }

    public requestRoom(room?: string, username?: string) {
        this.socket.emit("requestRoom", room, username);
    }

    public sendToggleCard(card: string) {
        this.socket.emit("toggleCard", card);
    }

    public sendLeaveRoom() {
        this.socket.emit("leaveRoom");
    }

    public sendStartRoom() {
        this.socket.emit("startRoom");
    }

    public sendPredictStiche(x : number) {
        this.socket.emit("predictStiche", x);
    }

    public sendUsername(username: string) {
        this.socket.emit("username", username);
    }

    public sendLayCard(card: any) {
        const cardD = useGameStore().cards.find((c) => c.id === card);
        if (!cardD) return;
        (cardD as any)._laying = true;
        setTimeout(() => {
            this.socket.emit("layCard", card);
        }, 250);
    }

}
<template>
    <div :class="{background : true, visible: !collapsed}" ref="background">

    </div>
    <div :class="{main: true, mobile: gamestore.verticalScreen}">
        <div class="topbar">        
            <button @click="toggleCollapse" class="toggle caveat-font">{{ collapsed ? 'Tabelle anzeigen' : 'Tabelle ausblenden' }}</button>
        </div>
        <div :class="{ wrapper: true, collapsed: collapsed }">
            <div>
                <PointTable />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import PointTable from '../PointTable.vue';
import { useGameStore } from '@/stores/gamestore';

const gamestore = useGameStore();
const collapsed = ref(true);

const toggleCollapse = () => {
    collapsed.value = !collapsed.value;
}

</script>

<style scoped>

.background{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-duration: 0.5s;
    pointer-events: none;
}

.background.visible{
    opacity: 1;
}


.main {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    text-align: center;
}
.main.mobile {
    width: 100%;

}
.wrapper {
    position: absolute;
    transform: translateY(0);
    overflow: hidden;
    transition-duration: 0.5s;
    background-color: white;
    background: url('~@/assets/hip-square.webp') 0 0 repeat;
    max-width: 100vw;
    overflow-y: scroll;
    padding: 5vmin;
    padding-top: 15vh;
    max-height: calc(100vh - 20vmin);

}
.wrapper.collapsed {
    transform: translateY(-110%);
    overflow: hidden;
    transition-duration: 0.5s;
}

.topbar{
    position: absolute;
    z-index: 11;
    top: 0;
    width: 100%;
    text-align: center;
    background: radial-gradient(rgba(197, 194, 187, 0.363), rgba(197, 194, 187, 0.384) 30%, rgba(30, 30, 30, 0) 70%,rgba(30, 30, 30, 0)) 0 0 no-repeat;
    background-size: 40% 200%;
    background-position: 50% 100%;
}

button.toggle {
    background-color: transparent;
    color: rgba(44, 44, 44, 0.774);
    border: none;
    font-size: 2vmax;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 1vmin;
}
</style>
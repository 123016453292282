<template>

    <div class="background" ref="background">

    </div>

    <div class="container" ref="container">
        <h3 class="cinzel-font ">Möchtest du das Spiel wirklich verlassen?</h3>

        <h4 v-if="props.alert">
            Wenn du das Spiel verlässt kannst du nicht mehr zurückkehren oder deine Verbindung wiederherstellen.<br>
            Alle deine Punkte gehen verloren.
        </h4>

        <button class="button red" @click="gamestore.client.sendLeaveRoom()">Ja</button>&nbsp;
        <button class="button" @click="gamestore.leaveWarning = false">Nein</button>
        <div class="whitebar top" ref="topbar">
            
        </div>
        <div class="whitebar bottom" ref="bottombar">
                
        </div>
    </div>

</template>

<script setup>
import { ref, defineProps } from 'vue'
import { useGameStore } from '@/stores/gamestore';

const gamestore = useGameStore();

const props = defineProps({
    alert: Boolean
})

const topbar = ref();
const bottombar = ref();
const container = ref();

</script>

<style scoped>

.container{
    position: absolute;
    text-align: center;
    position: absolute;
    width: 100vw;
    margin-left: -50vw;
    backdrop-filter: blur(5px);
    background-color: rgba(182, 140, 2, 0.5);
    animation: fadein 0.7s;
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.button{
    margin-bottom: 5vh;
}

.stichnr {
    display: inline-block;
    font-size: 4vh;
    margin: 0 2vw;
    color: white;
    border: 2px dotted white;
    border-radius: 50%;
    padding: 5px;
    width: 5.0vh; /* Add this line */
    height: 5.0vh; /* Add this line */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.stichnr:hover{
    background-color: rgba(255, 255, 255, 0.582);
    color: black;
}

.stichnr:active{
    background-color: rgba(0, 0, 0, 0.562);
    color: white;
}

.container.out{
    animation: fadeout 0.7s;
}

.background{
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin-left: -50vw;
    margin-top: -30vh;
    background-color: rgba(0, 0, 0, 0.596);
}

.background.out{
    animation: fadeout 0.7s;
}

.whitebar{
    position: absolute;
    width: 100vw;
    height: 1vmin;
    background-color: rgba(255, 255, 255, 0.274);
}

@keyframes slideinleft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideinright {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideoutleft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

@keyframes slideoutright {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.whitebar.top{
    top: 1vh;
    animation: slideinleft 0.5s;
}
.whitebar.top.out{
    animation: slideoutleft 0.5s;
}

.whitebar.bottom{
    bottom: 1vmin;
    animation: slideinright 0.5s;
}
.whitebar.bottom.out{
    animation: slideoutright 0.5s;
}


h1{
    padding-top: 5vmin;
    font-size: 5vh;
}
h3{
    font-size: 3vh;
    margin-top: 3vh;
}

h1, h3{
    color: rgb(185, 185, 185);
    text-shadow: 0 0 1vmin rgba(49, 30, 0, 0.781);
}

h4{
    font-size: 2vh;
    margin-top: 2vh;
    color: rgb(185, 185, 185);
    text-shadow: 0 0 1vmin rgba(49, 30, 0, 0.781);
}

</style>
<template>
  <div id="woodenbackground" :class="containerClasses">
    <RandomCardGrid />
  </div>
  <div id="inputwrapper">
    <h1 class="cinzel-font">Catana</h1><br>
    <h2 class="material-symbols-outlined">edit</h2>
    <input type="text" class="line-input caveat-font" placeholder="Name" v-model="username" @input="validUsername(username)" ref="input"/><br>
  </div>

  <div id="bottom" :class="containerClasses">
    <CodeInput :joinfunc="joinGame"/>
  </div>
  <div id="topmiddle">
  </div>
</template>

<script lang="ts" setup>
import { useGameStore } from '@/stores/gamestore';
import { computed, ref } from 'vue';
import CodeInput from './components/UI/CodeInput.vue';
import RandomCardGrid from './components/RandomCardGrid.vue';
const gamestore = useGameStore();

setInterval(() => {
  gamestore.updateScreenDetails()
}, 1000)

const containerClasses = computed(() => {
  return {
    touch: gamestore.touchDevice,
    vertical: gamestore.verticalScreen,
    over169: gamestore.over16to9,
    horizontal: !gamestore.verticalScreen
  };
});

const username = ref(sessionStorage.getItem('username') || localStorage.getItem('username') || "");
const input = ref();

function validUsername(username: string) {
    // check for characters that are not allowed and length
    if (!/[^a-zA-Z0-9]/.test(username) && username.length <= 15){
      input.value.classList.remove('red');
      localStorage.setItem('username', username);
      sessionStorage.setItem('username', username);
    }
    else {
      input.value.classList.add('red');
    }
}

function joinGame(code = "") {
  if (!/[^a-zA-Z0-9]/.test(username.value) && username.value.length <= 15){
    console.log("joining game with code: " + code);
    gamestore.client.requestRoom(code , username.value);
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Noto+Color+Emoji&display=swap');

body {
  background-image: url('~@/assets/tactile_noise.png');
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  position: fixed;
}



#topmiddle {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

#cardselection {
  position: absolute;
  width: 200%;
  height: 30vmin;
  top: 30vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

#gamecontainer.vertical.over169 #cardselection{
  top: 25vh;
}

#startbtn{
  margin-top: 10vmin;
}

#bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15vh;
  display: flex;
  padding-left: 5%;
  justify-content: flex-start;
  align-items: flex-start;
}

#inputwrapper{
  position: absolute;
  top: 15vh;
  width: 100%;
  padding-left: 5%;
}

#bottom.vertical {
  padding-left: 5%;
  justify-content: flex-start;
  align-items: flex-start;
}

input.line-input {

  background: none;
  border: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: rgba(233, 213, 183, 0.774);
  outline: none;
  overflow: visible;
  font-size: 10vmin;
  max-width: 80vw;
}
input.line-input.red {
  color: rgba(255, 134, 118, 0.979);
}

#gamecontainer.vertical #inputline {
  top: 33vmin;
}

#gamecontainer.vertical input.line-input {
  font-size: 15vmin !important;
}

.bottomcard{
  box-shadow: -2vmin 2vmin 6vmin rgba(0, 0, 0, 0.726) , inset -2vmin 2vmin 6vmin rgb(0, 0, 0, 0.726);
  border-radius: 10px;
}

#trumpflabel {
  text-align: center;
  position: absolute;
  top: calc(30vh + 29vmin);
  font-weight: bold;
  left: calc(20.2vw + 10vmin) ;
  transform: translateX(-50%);
  font-size: 5vmin;
  color: rgba(150, 150, 150, 0.774);
  text-shadow: 0 0 1vmin rgba(49, 30, 0, 0.781);
}

#stichholder {
  position: absolute;
  width: 20vmin;
  height: 30vmin;
  top: calc(30vh + 5vmin);
  right:20.2vw;
}

#playercontainer {
  position: absolute;
  width: 100%;
  height: 30vmin;
  top: 0;
  left: 0;
  text-align: center;
}

#gamecontainer.vertical #playercontainer{
  position: absolute;
  width: 100%;
  height: 30vmin;
  top: 20vh;
  left: 0;
  text-align: center;
}

#gamecontainer.vertical.over169 #playercontainer{
  top: 30vh;
}

#gamecontainer.horizontal > #stichholder{
  right: 50vw;
  transform: translateX(50%);
}

.cardstaple {
  position: absolute;
  width: 20vmin;
  height: 30vmin;
  top: 30vh;
  left:20.2vw;
  transform: scale(0.7);
}

.cardstaple.a1{
  top: 29.5vh;
  left: 20vw;
}

.cardstaple.a2{
  top: 29vh;
  left: 19.8vw;
}

.cardstaple.a3{
  top: 28.5vh;
  left: 19.6vw;
}

.cardstaple.a4{
  top: 28vh;
  left: 19.4vw;
}

h1 {
  font-size: 16vmin;
  margin: 0;
  padding: 0;
  color: rgba(233, 213, 183, 0.774);
  text-shadow: 0 0 1vmin rgba(128, 111, 84, 0.781);
}

h2 {
  font-size: 6vmin;
  margin: 0;
  padding: 0;
  color: rgba(233, 213, 183, 0.774);
}



#gamecontainer{
  transform: scale(0.7);
  width: 100%;
  height: 100%;
  position: relative;
}

#gamecontainer.vertical{
  transform: scale(0.8) translateY(8vmin);
}

#gamecontainer.vertical.over169{
  transform: scale(1) translateY(8vmin);
}


#woodenbackground {
  position: absolute;
  overflow: hidden;
  transform: perspective(1000px) rotateX(15deg) scale(1.10);
  width: 110%;
  left: -5%;
  height: 110%;
  bottom: 0;
}

#woodenbackground.vertical.over169 {
  bottom: 0vmin;
}

#woodenbackground::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  background-size: 50vmin;
}


</style>
